export const CREATE_CAMPAIGN = "CREATE YOUR CAMPAIGN";
export const MANAGE_CAMPAIGN = "MANAGE YOUR AR CAMPAIGN";
export const MANAGE_ANALYTICS = "ANALYTICS OF AR CAMPAIGN" 
export const MANAGE_ANALYTICS_EDIT = "Manage AR Campaign" 

export const NAV_PATHS = {
  CREATE: "create",
  MANAGE: "manage",
  ANALYTICS: "analytics",
};
