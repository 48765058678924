import React from "react";

const Loading = () => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50 backdrop-blur-md">
      <div className="text-white text-center">
        <img
          src="/logo/unwrap.png"
          alt="Logo"
          height={200}
          width={200}
          className="animate-pulse"
        />
            <div className="text-[#5a5a5a] text-xl animate-pulse">Loading</div>
      </div>
    </div>
  );
};

export default Loading;
