import React from "react";
import { CreateCampaignForm } from "../../../../../components/Campaign";
import RootLayout from "../../../../../layout/layout";

const EditCampaign = () => {
  return (
    <RootLayout>
      <div className="sm:pt-2 md:pt-5 mx-10 px-3 sm:px-10 pb-5 bg-[#0D0D0D] h-full flex-1 flex">
        <CreateCampaignForm />
      </div>
    </RootLayout>
  );
};

export default EditCampaign;
