
import React from "react";
import { ButtonProps } from "../../types/Button";
import { cn } from "../../utils/cn";

const Button = ({
  type = "button",
  variant = "default",
  onClick,
  children,
  className = "",
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={cn(
        `btn-${variant}`,
        "text-sm px-5 py-2.5 me-2 mb-2",
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
