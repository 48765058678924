import React from "react";
import ManageCampaign from "../../../../components/Campaign/Manage/ManageCampaign";
import RootLayout from "../../../../layout/layout";

const ManagePage = () => {
  return (
    <RootLayout>
      <div className="sm:pt-5 md:pt-10 mx-10 px-10 pb-5 bg-[#0D0D0D]">
        <ManageCampaign />
      </div>
    </RootLayout>
  );
};

export default ManagePage;
