import React from "react";
import Button from "../../components/Button/Button";
import { loginSchema } from "../../components/Campaign/Create/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginFormValues } from "../../types/campaign/campaign.tpes";
import { useForm } from "react-hook-form";
import { useAuth } from "../../context/useAuth";
import LoadingState from "../../components/Loading/Loading";
import { useNavigate } from "react-router";

const AuthPage = () => {
  const [loading, setLoading] = React.useState(false);
  const { loginUser } = useAuth();
  const methods = useForm<LoginFormValues>({
    resolver: yupResolver(loginSchema),
  });

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    reset,
    watch,
    register,
    setError,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: LoginFormValues) => {
    setLoading(true);
    await loginUser(data.username, data.password)
      .then((res: any) => {
        navigate("/dashboard/campaign/manage");
      })
      .catch((err: any) => {
        setError("password", { message: err });
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 h-screen">
      {loading && <LoadingState />}
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img className="w-52 h-24 mr-2" src="/logo/unwrap.png" alt="logo" />
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in to your account
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <label
                  htmlFor="username"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Username
                </label>
                <input
                  type="username"
                  id="username"
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  {...register("username")}
                />
                {errors.username && (
                  <span className="text-red-400">
                    {errors.username.message}
                  </span>
                )}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  placeholder=""
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  {...register("password")}
                />
                {errors.password && (
                  <span className="text-red-400">
                    {errors.password.message}
                  </span>
                )}
              </div>
              <div className="flex items-center justify-between">
                {/* <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="remember"
                      aria-describedby="remember"
                      type="checkbox"
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="remember"
                      className="text-gray-500 dark:text-gray-300"
                    >
                      Remember me
                    </label>
                  </div>
                </div> */}
                {/* <a
                  href="#"
                  className="text-sm font-medium text-white hover:underline dark:text-primary-500 "
                >
                  Forgot password?
                </a> */}
              </div>
              <Button variant="yellow" type="submit" className="w-full">
                Sign in
              </Button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuthPage;
