import * as Yup from "yup";

export const createCampaignvalidationSchema = Yup.object().shape({
  campaignName: Yup.string()
    .required("Campaign name is required")
    .max(50, "Campaign name must be less than 50 characters"),
  allocatedCoinCount: Yup.number()
    .typeError("Amount must be a number")
    .required("Allocated coin count is required")
    .min(1, "Coin count must be at least 1"),
  perOneScan: Yup.number()
    .typeError("Per one scan must be a number")
    .required("Number of scans is required")
    .min(1, "Must be at least 1 scan"),
  unlimitedScans: Yup.string()
    .required("Number of scans is required")
    .min(1, "Must be at least 1 scan"),
  numberOfScans: Yup.number()
    .typeError("Number of scans must be a number")
    .required("Number of scans is required")
    .min(1, "Must be at least 1 scan"),
  campaignDateRange: Yup.object().shape({
    startDate: Yup.date().required("Start date is required"),
    endDate: Yup.date()
      .required("End date is required")
      .min(Yup.ref("startDate"), "End date must be after start date"),
  }),
  campaignType: Yup.string().required("Campaign type is required"),
  discountedProducts: Yup.array()
    .of(Yup.string().required("Each product must be a string"))
    .min(1, "At least one product must be selected")
    .required("Discounted products are required"),
  discountArrangement: Yup.number()
    .typeError("Discount arrangement be a number")
    .required("Discount arrangement is required")
    .min(0.01, "Discount must be at least 0.01 per coin"),
});

export const loginSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
});
