import React from "react";
import { MultiStatCardTypesProps } from "./types";

const MultiStatCard = ({ data }: MultiStatCardTypesProps) => {
  return (
    <div className="bg-[#1A1A1A] px-3 w-fit">
      <table className="text-sm text-left rtl:text-right text-[#FFFFFF] h-52">
        <tbody>
          {data.map((tabledata, idx: number) => (
            <tr
              key={idx}
              className={`bg-[#1A1A1A] ${
                idx + 1 != data.length && "border-b border-gray-600"
              }`}
            >
              <th
                scope="row"
                className="px-4 py-4 font-bold text-2xl text-[#FFFFFF] whitespace-nowrap border-r border-gray-600"
              >
                {tabledata.header}
              </th>
              <td className="px-6 py-4 font-bold text-3xl text-[#00FF47]">
                {tabledata.value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MultiStatCard;
