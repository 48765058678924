import React from "react";
import { MultiStatCard, StatCard } from "../../../../../components/StatCatds";
import { multiStatData } from "../../../../../constants/analyticsMultiStat.constant";
import RootLayout from "../../../../../layout/layout";

const AnalyticViewPage = () => {
  return (
    <RootLayout>
      <div className="px-20 bg-[#0D0D0D] pb-5 h-full sm:pt-5 ">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          <div>
            <StatCard header="Active Users" count={108} />
          </div>
          <div className="flex justify-start md:justify-end lg:justify-start">
            <StatCard header="FULLY EARNED COIN COUNT" count={12678} />
          </div>
          <div>
            <MultiStatCard data={multiStatData} />
          </div>
          <div className="flex justify-start md:justify-end lg:justify-start">
            <StatCard header="ENGAGEMENT TIME" count="5 MIN" />
          </div>
          <div>
            <StatCard header="redeem count" count={1524} />
          </div>
        </div>
      </div>
    </RootLayout>
  );
};

export default AnalyticViewPage;
