import React from "react";
import { useController } from "react-hook-form";

type Props = {
  label?: string;
  name: string;
  type: string;
  control: any;
  placeholder?: string;
  required?: boolean;
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  disabled?: boolean;
  other?: any;
  width?: string;
};

const InputText = ({
  label,
  type,
  name,
  control,
  required,
  placeholder,
  size,
  fullWidth,
  disabled,
  other,
  width,
}: Props) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: "",
  });

  return (
    <div>
      {label && (
        <label
          htmlFor={name}
          className="block text-md font-normal text-2xl text-white"
        >
          {label}
        </label>
      )}
      <div className="mt-1">
        <input
          id={name}
          type={type}
          placeholder={placeholder}
          ref={ref}
          required={required}
          disabled={disabled}
          {...other}
          {...inputProps}
          className={`${fullWidth ? "w-full" : ""} ${
            size == "small"
              ? "px-3 py-2 text-sm"
              : size == "large"
              ? "px-5 py-5 text-lg"
              : "px-4 py-3 text-md"
          } block appearance-none rounded-md ${
            type != "file" ? "shadow-sm" : ""
          } placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 bg-[#1A1A1A] text-white h-12 ${width}`}
        />
      </div>
      {error && <span className="text-red-500 text-xs">{error.message}</span>}
    </div>
  );
};

export default InputText;
