import React, { useEffect, useState } from "react";
import { ManageTableConst } from "../../../constants/manage";
import { useLocation, useNavigate } from "react-router";
import { getAllCampaigns } from "../../../api/campaign";
import { CampignResponse } from "../../../types/campaign/campaign.tpes";
import LoadingState from "../../Loading/Loading";

const ManageCampaign = () => {
  const [campaigns, setCampaigns] = useState<CampignResponse[] | undefined>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location.pathname.split("/").at(-1);

  useEffect(() => {
    setLoading(true);
    getAllCampaigns()
      .then((res) => {
        setCampaigns(res);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {loading && <LoadingState />}
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg h-[380px] xl:h-[480px] overflow-auto">
        <table className="w-full text-sm text-left rtl:text-right text-[#808080] border-separate border-spacing-y-3 ">
          <thead className="text-xs text-[#808080] uppercase bg-gray-50"></thead>
          <tbody>
            {campaigns?.map((item, idx) => (
              <tr
                key={idx}
                className="bg-[#1A1A1A] border-b hover:bg-gray-800 cursor-pointer"
                onClick={() => {
                  navigate(
                    `/dashboard/campaign/${currentPath}/edit/${item.id}`
                  );
                }}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-[#808080] whitespace-nowrap"
                >
                  {idx + 1}
                </th>
                <td className="px-6 py-4">{item.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageCampaign;
