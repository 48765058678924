import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useForm, Controller, useFieldArray, set } from "react-hook-form";
import { createCampaignvalidationSchema } from "../";

import { CreateCampaignFormValues } from "../../../types";
import InputText from "../../form/hook-form/InputText";
import FormProvider from "../../form/hook-form/FormProvider";
import SelectDropdown from "../../form/hook-form/InputSelect";
import Button from "../../Button/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate, useParams } from "react-router";
import RadioButton from "../../form/hook-form/radioButton";
import {
  addNewCampaign,
  deleteCampaignBtId,
  getCampaignByid,
  updateCampaign,
} from "../../../api/campaign";
import moment from "moment";
import LoadingState from "../../Loading/Loading";
import MyModal from "../../Modal";
import { CampignResponse } from "../../../types/campaign/campaign.tpes";

const CreateCampaign = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { id } = useParams<{ id: string }>();
  const search = searchParams.get("id");
  const [productsArray, setProductsArray] = useState<string[]>([]);
  const [product, setProduct] = useState<string>("");
  const [edit, isEdit] = useState(!!id);
  const [Loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<
    CampignResponse | undefined
  >();
  const [openDelete, setOpenDelete] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const defaultValues = {
    perOneScan: 1,
    unlimitedScans: "unlimited",
  };

  const methods = useForm<CreateCampaignFormValues>({
    resolver: yupResolver(createCampaignvalidationSchema),
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: CreateCampaignFormValues) => {
    const values = {
      name: data.campaignName,
      coin_count: data.allocatedCoinCount,
      scan_count: data.unlimitedScans,
      scan_number: data.perOneScan,
      date_range: `${moment(data.campaignDateRange.startDate).format(
        "YYYY-MM-DD"
      )} to ${moment(data.campaignDateRange.endDate).format("YYYY-MM-DD")}`,
      type: data.campaignType,
      products: data.discountedProducts,
      discount: `${data.discountArrangement}%`,
    };
    setLoading(true);
    if (!edit) {
      await addNewCampaign(values)
        .then((res) => {
          setSuccessMessage("Campaign Added Successfully !");
          setIsOpen(true);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      updateCampaign(String(id), values)
        .then((res) => {
          setSuccessMessage("Campaign Updated Successfully");
          setIsOpen(true);
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const typeOptions = [
    {
      label: "Select a campaign type",
      value: "",
    },
    {
      label: "Web games and Immersive expirences",
      value: "web_games_and_immersive_expirences",
    },
    {
      label: "AR/VR",
      value: "ar",
    },
    {
      label: "Challenges",
      value: "challenges",
    },
    {
      label: "Metaverse activations",
      value: "metaverse_activations",
    },
    {
      label: "Transactions",
      value: "transactions",
    },
  ];

  useEffect(() => {
    if (edit && id) {
      setLoading(true);
      getCampaignByid(id)
        .then((res) => {
          setCurrentProduct(res);
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [edit, id]);

  useEffect(() => {
    if (edit && currentProduct) {
      setValue("campaignName", currentProduct.name);
      setValue("allocatedCoinCount", currentProduct.coin_count);
      setValue("perOneScan", currentProduct.scan_number);
      setValue("unlimitedScans", currentProduct.scan_count);
      setValue("numberOfScans", currentProduct.scan_number);
      setValue(
        "campaignDateRange.startDate",
        moment(currentProduct.date_range.split("to")[0].trim()).toDate()
      );

      setValue(
        "campaignDateRange.endDate",
        moment(currentProduct.date_range.split("to")[1].trim()).toDate()
      );
      setValue("campaignType", currentProduct.type);
      setValue("discountedProducts", currentProduct.products);
      setValue(
        "discountArrangement",
        parseInt(currentProduct.discount.replace("%", ""))
      );
      setProductsArray(currentProduct.products);
    }
  }, [edit, currentProduct]);

  const onProductSubmit = () => {
    if (product === "") {
      setError("discountedProducts", { message: "Product name is required" });
      return;
    } else {
      setValue("discountedProducts", [
        ...(watch("discountedProducts") || []),
        product,
      ]);
      setProductsArray(
        productsArray ? [...productsArray, product || ""] : [product || ""]
      );
      setProduct("");
      setError("discountedProducts", { message: "" });
    }
  };

  const deleteCampaign = async (id: string) => {
    setLoading(true);
    await deleteCampaignBtId(id)
      .then((res) => {
        setSuccessMessage("Campaign Deleted Successfully");
        setOpenDelete(false);
        setIsOpen(true);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="w-full">
      {Loading && <LoadingState />}
      <MyModal isOpen={isOpen} setIsOpen={setIsOpen} isTransparent>
        <div className="flex flex-col items-center space-y-5">
          <div className="text-yellow-500 font-bold">{successMessage}</div>
          <button
            onClick={() => {
              setIsOpen(false);
              navigate("/dashboard/campaign/manage");
            }}
            className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
          >
            OK
          </button>
        </div>
      </MyModal>
      <MyModal isOpen={openDelete} setIsOpen={setOpenDelete} isTransparent>
        <div className="flex flex-col items-center space-y-5">
          <div className="text-red-500 font-bold">
            Are you sure want to Delete this Campaign ?
          </div>
          <button
            onClick={() => {
              deleteCampaign(String(id));
            }}
            className="inline-flex items-center gap-2 rounded-md bg-red-500 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700"
          >
            DELETE
          </button>
        </div>
      </MyModal>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <section className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div className="space-y-3 text-white text-md font-normal text-2xl">
            <InputText
              fullWidth
              size="small"
              type="text"
              label="Name of the campaign"
              name="campaignName"
              control={control}
            />
            <InputText
              fullWidth
              size="small"
              type="number"
              label="Allocated coin counts for the scan"
              name="allocatedCoinCount"
              control={control}
            />
            <div className="space-y-3 ">
              <span>How many times do users get coins?</span>
              <div className="flex justify-between">
                <div className="flex space-x-2 items-center justify-center">
                  <span>Per one scan</span>
                  <RadioButton
                    name="perOneScan"
                    control={control}
                    options={[{ value: 1, label: "" }]}
                    required={true}
                  />
                </div>
                <div className="flex space-x-2 items-center justify-center">
                  {/* <span>Unlimited scans</span>
                  <InputText
                    disabled
                    size="small"
                    type="text"
                    name="unlimitedScans"
                    control={control}
                    width="w-24"
                  /> */}
                </div>
              </div>
            </div>
            <div className="flex space-x-2">
              <span>Number of scans</span>
              <InputText
                size="small"
                type="number"
                name="numberOfScans"
                control={control}
              />
            </div>
            <div className="space-y-2">
              <span>Campaign date range</span>
              <div className="flex items-center">
                <div className="flex flex-col space-y-1">
                  <Controller
                    control={control}
                    name="campaignDateRange.startDate"
                    render={({ field }) => (
                      <ReactDatePicker
                        dateFormat={"MM/dd/yyyy"}
                        className="h-12 w-36 bg-[#1A1A1A] rounded-md"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                      />
                    )}
                  />
                  {errors.campaignDateRange && (
                    <span className="text-sm text-red-500">
                      {errors.campaignDateRange.startDate?.message}
                    </span>
                  )}
                </div>
                <div> - </div>
                <div className="flex flex-col space-y-1">
                  <Controller
                    control={control}
                    name="campaignDateRange.endDate"
                    render={({ field }) => (
                      <ReactDatePicker
                        dateFormat={"MM/dd/yyyy"}
                        wrapperClassName="date-picker"
                        className="h-12  w-36 bg-[#1A1A1A] rounded-md"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                      />
                    )}
                  />
                  {errors.campaignDateRange && (
                    <span className="text-sm text-red-500">
                      {errors.campaignDateRange.endDate?.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-3 text-white text-md font-normal text-2xl">
            <SelectDropdown
              fullWidth
              size="small"
              name="campaignType"
              control={control}
              label="Campaign type"
              options={typeOptions}
            />
            <div className="space-y-1">
              <span>Which products get discount by earned coins</span>

              <div className="flex  bg-[#1A1A1A] items-center px-2 rounded-md space-x-2">
                <input
                  value={product}
                  onChange={(e) => setProduct(e.target.value)}
                  type="text"
                  className="h-12 w-full bg-[#1A1A1A] px-2"
                />
                <button
                  type="button"
                  onClick={onProductSubmit}
                  className="bg-black text-[#a9a9a9] py-1 px-2 rounded-lg hover:text-[#242323]"
                >
                  Add
                </button>
              </div>
            </div>
            <span className="text-[12px] text-red-500">
              {errors.discountedProducts?.message}
            </span>
            <div className="bg-[#1A1A1A] h-32 space-y-1 overflow-y-auto p-1 rounded-md">
              {productsArray.map((product, key) => (
                <div
                  key={key}
                  className=" bg-black text-[#cfcfcf] py-1 px-2 flex justify-between rounded-md items-center"
                >
                  <span>{product}</span>
                  <div
                    className="text-sm text-[#cfcfcf] hover:text-[#373535] hover:cursor-pointer"
                    onClick={() => {
                      const newProductsArray = productsArray.filter(
                        (item, index) => index !== key
                      );

                      setProductsArray(newProductsArray);

                      setValue("discountedProducts", newProductsArray);
                    }}
                  >
                    X
                  </div>
                </div>
              ))}
            </div>
            <div className="space-y-2">
              <span>Discount Arrangement</span>
              <div className="flex space-x-2 items-center">
                <span className="">1 Coin = </span>
                <InputText
                  size="small"
                  type="number"
                  name="discountArrangement"
                  control={control}
                />
                %
              </div>
            </div>
            <div className="flex space-x-1">
              {!edit ? (
                <Button
                  variant="yellow"
                  className="h-10 w-full sm:w-fit"
                  type="submit"
                >
                  Create campaign
                </Button>
              ) : (
                <div className="flex sm:justify-start justify-around w-full">
                  <Button variant="green" className="h-10" type="submit">
                    Update
                  </Button>
                  <Button
                    variant="red"
                    className="h-10 w-28"
                    type="button"
                    onClick={() => {
                      setOpenDelete(true);
                    }}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
          </div>
        </section>
      </FormProvider>
    </div>
  );
};

export default CreateCampaign;
