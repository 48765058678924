export const multiStatData = [
  {
    header: "Reach",
    value: 150234,
  },
  {
    header: "Impressions",
    value: 126780,
  },
  {
    header: "Engagement",
    value: 100234,
  },
];
