import React, { useState } from "react";
import { useController } from "react-hook-form";

type Props = {
  label?: string;
  name: string;
  control: any;
  required?: boolean;
  options: Array<{ value: string; label: string }>;
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  other?: any;
};

const SelectDropdown = ({
  label,
  name,
  control,
  required,
  options,
  size,
  fullWidth,
  other,
}: Props) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: options[0].value,
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleFocus = () => setIsOpen(true);
  const handleBlur = () => setIsOpen(false);

  return (
    <div className="relative">
      {label && (
        <label className="block text-md font-normal text-2xl text-white mb-1">
          {label}
        </label>
      )}
      <div className="relative mt-1">
        <select
          ref={ref}
          required={required}
          {...other}
          {...inputProps}
          className={`${fullWidth ? "w-full" : ""} ${
            size === "small"
              ? "px-3 py-3 text-sm"
              : size === "large"
              ? "px-5 py-5 text-lg"
              : "px-4 py-3 text-md"
          } block appearance-none rounded-md px-3 py-2 placeholder-gray-400 shadow-sm bg-[#1A1A1A] text-white h-12 pr-10`}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          {options.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>

        {/* SVG Icon for Dropdown */}
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-5 w-5 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 15.75 7.5-7.5 7.5 7.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-5 w-5 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          )}
        </div>
      </div>
      {error && <span className="text-red-500 text-xs">{error.message}</span>}
    </div>
  );
};

export default SelectDropdown;
