import React from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  CREATE_CAMPAIGN,
  MANAGE_ANALYTICS,
  MANAGE_ANALYTICS_EDIT,
  MANAGE_CAMPAIGN,
  NAV_PATHS,
} from "../../../constants/navigation.constants";

import Button from "../../Button/Button";

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { id } = useParams<{ id: string }>();

  const search = searchParams.get("id");

  const params = useParams<Record<string, string | undefined>>();

  const currentPath = location.pathname.split("/");

  const buttonVariant = (param: string) =>
    currentPath.includes(param) ? "default" : "yellow";

  const navigateUser = (param: string) => {
    switch (param) {
      case NAV_PATHS.CREATE:
        navigate(`/dashboard/campaign/${NAV_PATHS.CREATE}`);
        break;

      case NAV_PATHS.MANAGE:
        navigate(`/dashboard/campaign/${NAV_PATHS.MANAGE}`);
        break;

      case NAV_PATHS.ANALYTICS:
        navigate(`/dashboard/campaign/${NAV_PATHS.ANALYTICS}`);
        break;

      default:
        break;
    }
  };

  const CampaignTitle = (param: string | undefined) => {
    switch (param) {
      case NAV_PATHS.CREATE:
        return CREATE_CAMPAIGN;

      case NAV_PATHS.MANAGE:
        return MANAGE_CAMPAIGN;

      case NAV_PATHS.ANALYTICS:
        return MANAGE_ANALYTICS;

      default:
        break;
    }
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between p-2 sm:p-9 items-center sm:items-start sm:space-y-0 space-y-2">
        <img src="/logo/unwrap.png" alt="Logo" height={200} width={200} />
        {!id ? (
          <h1 className="text-white font-bold text-3xl text-center">
            {CampaignTitle(currentPath.at(-2))}
          </h1>
        ) : (
          <div className="bg-[#1A1A1A] md:w-fit lg:w-[620px] xl:w-[820px] py-2 px-9 rounded-md">
            <h1 className="text-[#636363] font-normal text-2xl text-center">
              {currentPath.at(-2) === NAV_PATHS.ANALYTICS
                ? MANAGE_ANALYTICS_EDIT
                : NAV_PATHS.MANAGE && MANAGE_ANALYTICS_EDIT}
            </h1>
          </div>
        )}
        <div className="sm:whitespace-nowrap whitespace-normal">
          {!id ? (
            <>
              <Button
                variant={buttonVariant("create")}
                onClick={() => {
                  navigateUser(NAV_PATHS.CREATE);
                }}
              >
                Create
              </Button>
              <Button
                variant={buttonVariant("manage")}
                onClick={() => {
                  navigateUser(NAV_PATHS.MANAGE);
                }}
              >
                Manage
              </Button>
              <Button
                variant={buttonVariant("analytics")}
                onClick={() => {
                  navigateUser(NAV_PATHS.ANALYTICS);
                }}
              >
                Analytics
              </Button>
            </>
          ) : (
            <Button
              className="w-32"
              variant="default"
              onClick={() => {
                navigate(`/dashboard/campaign/${currentPath.at(-3)}`);
              }}
            >
              Back
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
