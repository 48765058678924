import { NavBar } from "../components/Navigation/NavBar";

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <div className="min-h-screen flex flex-col bg-black">
      <section className="h-fit">
        <NavBar />
      </section>
      <section className="flex-1">{children}</section>
    </div>
  );
}
