import React, { createContext, useEffect, useState } from "react";
import { UserProfile } from "../types/User";
import { useNavigate } from "react-router";
import axios, { AxiosError } from "axios";

type UserContextType = {
  user: UserProfile | null;
  token: string | null;
  loginUser: (userName: string, password: string) => any;
  isLoggedIn: () => boolean;
  logOut: () => void;
};

type Props = { children: React.ReactNode };

const UserContext = createContext<UserContextType>({} as UserContextType);

export const UserProvider = ({ children }: Props) => {
  const navigate = useNavigate();

  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<UserProfile | null>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setAxiosAuthHeader(token);
  }, [token]);

  const setAxiosAuthHeader = (token: string | null) => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token.replace(
        /"/g,
        ""
      )}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(JSON.parse(storedToken));
      setAxiosAuthHeader(JSON.parse(storedToken));
    }
    setIsReady(true);
  }, []);

  const loginUser = async (username: string, password: string) => {
    const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:8080";
    return await axios
      .post(`${baseUrl}brand-admin-api/brand_admin_login`, {
        username,
        password,
      })
      .then((res) => {
        localStorage.setItem("token", JSON.stringify(res.data.token));
        setToken(res.data.token);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          throw "Authentication failed";
        }
        console.error(err);
      });
  };

  const isLoggedIn = () => {
    return !!token;
  };
  const logOut = () => {
    localStorage.removeItem("token");
    setToken(null);
    navigate("/");
  };

  return (
    <UserContext.Provider
      value={{ loginUser, user, token, logOut, isLoggedIn }}
    >
      {isReady ? children : null}
    </UserContext.Provider>
  );
};

export const useAuth = () => React.useContext(UserContext);
