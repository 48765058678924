import axios from "axios";
import { BASE_URL } from "../config/config";
import { addNewCampaign as NewCampaign } from "../types/campaign/campaign.tpes";

export const addNewCampaign = async (campaign: NewCampaign) => {
  return await axios
    .post(`${BASE_URL}campaigns-api/add_new_campaigns`, campaign)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const getAllCampaigns = async () => {
  return await axios
    .get(`${BASE_URL}campaigns-api/get_all_campaigns`)
    .then((res) => {
      if (res.status === 200) {
        return res?.data?.campaigns;
      }
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const getCampaignByid = async (id: string) => {
  return await axios
    .get(`${BASE_URL}campaigns-api/get_campaigns_by_id/${id}`)
    .then((res) => {
      return res.data.campaign;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateCampaign = async (id: string, campaign: NewCampaign) => {
  return await axios
    .post(`${BASE_URL}campaigns-api/update_campaigns_by_id/${id}`, campaign)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteCampaignBtId = async (id: string) => {
  return await axios
    .get(`${BASE_URL}campaigns-api/delete_campaigns_by_id/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw new Error(err);
    });
};
