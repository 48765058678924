import React from "react";
import { Controller } from "react-hook-form";

type Props = {
  label?: string;
  name: string;
  control: any;
  options: { value: string | number; label: string }[];
  required?: boolean;
  disabled?: boolean;
};

const RadioButton = ({
  label,
  name,
  control,
  options,
  required = false,
  disabled = false,
}: Props) => {
  return (
    <div>
      {label && (
        <label className="block text-md font-normal text-2xl text-white">
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field, fieldState: { error } }) => (
          <div className="mt-1">
            {options.map((option) => (
              <label
                key={option.value}
                className="inline-flex items-center mr-4"
              >
                <input
                  type="radio"
                  value={option.value}
                  checked={field.value === option.value}
                  onChange={() => field.onChange(option.value)}
                  disabled={disabled}
                  className={`form-radio text-indigo-600 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 bg-[#1A1A1A] text-white h-4 w-4`}
                />
                <span className="ml-2 text-white">{option.label}</span>
              </label>
            ))}
            {error && (
              <span className="text-red-500 text-xs">{error.message}</span>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default RadioButton;
