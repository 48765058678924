import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import AuthPage from "./pages/auth";
import ManagePage from "./pages/dashboard/campaign/manage";
import CreateCampaign from "./pages/dashboard/campaign/create";
import EditCampaign from "./pages/dashboard/campaign/manage/edit";
import Analytics from "./pages/dashboard/campaign/analytics";
import AnalyticViewPage from "./pages/dashboard/campaign/analytics/edit";
import { UserProvider } from "./context/useAuth";
import ProtectedRoute from "./routes/ProtectedRoutes";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route path="/" Component={AuthPage} />
          <Route
            path="/dashboard/campaign/manage"
            element={
              <ProtectedRoute>
                <ManagePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/campaign/create"
            element={
              <ProtectedRoute>
                <CreateCampaign />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/campaign/manage/edit/:id"
            element={
              <ProtectedRoute>
                <EditCampaign />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/campaign/analytics"
            element={
              <ProtectedRoute>
                <Analytics />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/campaign/analytics/edit/:id"
            element={
              <ProtectedRoute>
                <AnalyticViewPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </UserProvider>
    </Router>
  );
}

export default App;
