import React from "react";
import { StatCardTypesProps } from "./types";

const StatCard = ({ header, count }: StatCardTypesProps) => {
  return (
    <div className="bg-[#1A1A1A] w-fit p-3 h-52 rounded-md">
      <span className="text-[#FFFFFF] uppercase font-bold pr-20">{header}</span>
      <div className="flex justify-center items-center h-full pb-6">
        <span className="text-7xl font-bold text-[#00FF47]">{count}</span>
      </div>
    </div>
  );
};

export default StatCard;
